import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import LazyLoad from "react-lazyload";

import styled from "styled-components";
import history from "../history";
import queryString from "query-string";

import {
  getDokter,
  getRekomendasi,
  getJadwal,
  clearJadwal,
  clearRekomendasi,
  clearDokter
} from "../actions";

import NothingSvg from "../svg/nothing.svg";
import Loader from "../components/Loader";
import DokterList from "../components/DokterList";
import JadwalList from "../components/JadwalList";
import Button from "../components/Button";
import Header from "../components/Header";
import Loading from "../components/Loading";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon
} from "react-share";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media ${props => props.theme.mediaQueries.medium} {
    align-items: center;
  }
`;

const DokterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  margin-bottom: 7rem;
  opacity: ${props => (props.loaded ? "1" : "0")};
  visibility: ${props => (props.loaded ? "visible" : "hidden")};
  transition: all 600ms cubic-bezier(0.215, 0.61, 0.355, 1);

  @media ${props => props.theme.mediaQueries.largest} {
    max-width: 105rem;
  }
  @media ${props => props.theme.mediaQueries.larger} {
    max-width: 110rem;
    margin-bottom: 6rem;
  }
  @media ${props => props.theme.mediaQueries.large} {
    max-width: 110rem;
    margin-bottom: 5rem;
  }
  @media ${props => props.theme.mediaQueries.medium} {
    flex-direction: column;
    margin-bottom: 5rem;
  }
`;

const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;

const Heading = styled.div`
  color: var(--color-primary-dark);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.4rem;
`;

const Title = styled.h1`
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 1;
  color: var(--color-primary);
`;

const SubTitle = styled.h2`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.2rem;
  color: var(--color-primary);
`;

const DetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
`;

const ShareWrapper = styled.div`
  margin-bottom: 5rem;
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 40%;
  flex: 1 1 40%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 4rem;

  @media ${props => props.theme.mediaQueries.largest} {
    padding: 3rem;
  }
  @media ${props => props.theme.mediaQueries.large} {
    padding: 2rem;
  }
  @media ${props => props.theme.mediaQueries.smaller} {
    margin-bottom: 2rem;
  }
  @media ${props => props.theme.mediaQueries.medium} {
    width: 60%;
    max-width: 60%;
    flex: 1 1 60%;
  }
`;

const ImgLoad = styled.div`
  width: 100%;
  display: flex;
  max-width: 40%;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: all 100ms cubic-bezier(0.645, 0.045, 0.355, 1);

  @media ${props => props.theme.mediaQueries.smaller} {
    height: 28rem;
  }
`;

const DokterImg = styled.img`
  max-height: 100%;
  height: ${props => (props.error ? "25rem" : "auto")};
  object-fit: ${props => (props.error ? "contain" : "cover")};
  padding: ${props => (props.error ? "2rem" : "")};
  max-width: 100%;
  border-radius: 0.8rem;
  box-shadow: ${props =>
    props.error ? "none" : "0rem 2rem 5rem var(--shadow-color-dark)"};
  @media ${props => props.theme.mediaQueries.medium} {
    font-size: 1.2rem;
  }
`;

const DokterDetails = styled.div`
  width: 100%;
  max-width: 60%;
  padding: 4rem;
  flex: 1 1 60%;

  @media ${props => props.theme.mediaQueries.largest} {
    padding: 3rem;
  }
  @media ${props => props.theme.mediaQueries.large} {
    padding: 2rem;
  }
  @media ${props => props.theme.mediaQueries.smaller} {
    padding: 1rem;
  }
  @media ${props => props.theme.mediaQueries.smallest} {
    padding: 0rem;
  }
  @media ${props => props.theme.mediaQueries.medium} {
    width: 100%;
    max-width: 100%;
    flex: 1 1 100%;
  }
`;

const Text = styled.p`
  font-size: 1.3rem;
  line-height: 1.6;
  color: var(--link-color);
  font-weight: 500;
  margin-bottom: 3rem;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media ${props => props.theme.mediaQueries.small} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LeftButtons = styled.div`
  margin-right: auto;
  display: flex;

  @media ${props => props.theme.mediaQueries.small} {
    margin-bottom: 2rem;
  }

  & > *:not(:last-child) {
    margin-right: 2rem;

    @media ${props => props.theme.mediaQueries.large} {
      margin-right: 1rem;
    }
  }
`;

const Dokter = ({
  location,
  match,
  geral,
  dokter,
  getDokter,
  clearDokter,
  rekomendasi,
  getRekomendasi,
  jadwal,
  getJadwal,
  clearJadwal,
  clearRekomendasi
}) => {
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const params = queryString.parse(location.search);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    getDokter(match.params.id);
    return () => {
      clearDokter();
      setLoaded(false);
    };
  }, [match.params.id]);

  useEffect(() => {
    getRekomendasi(match.params.id, params.p);
    return () => clearRekomendasi();
  }, [params.p]);

  useEffect(() => {
    window.scrollTo({
      top: (0, 0),
      behavior: "smooth"
    });
    getJadwal(match.params.id);
    return () => {
      clearJadwal();
      setLoaded(false);
    };
  }, [match.params.id]);

  if (dokter.loading) {
    return <Loader />;
  }

  function renderBack() {
    if (history.action === "PUSH") {
      return (
        <div onClick={history.goBack}>
          <Button title="Back" solid left icon="arrow-left" />
        </div>
      );
    }
  }

  const url =
  process.env.NODE_ENV === "development"
    ? `http://192.168.132.2/foto/`
    : `https://simrspersahabatan.co.id/foto/`;

  return ( //console.log(jadwal),
    <Wrapper>
      <Helmet>
        <meta content="dokter" property="og:type" />
        <meta content="%PUBLIC_URL%/logo192.png" property="og:image" />
        <meta
          content={`${dokter.nama_dokter} - Dokter RS Persahabatan`}
          property="og:title"
        />
        <meta
          name="description"
          content="Pencarian Dokter RS Persahabatan Jakarta Timur"
          property="og:description"
        />
        <link
          rel="canonical"
          href={`https://dokter.rspersahabatan.co.id/dokter/${dokter.id}`}
        />
        <title>{`${dokter.nama_dokter} - Dokter RS Persahabatan`}</title>
      </Helmet>
      <LazyLoad height={500}>
        <DokterWrapper loaded={loaded ? 1 : 0}>
          {!loaded ? (
            <ImgLoad>
              <Loading />
            </ImgLoad>
          ) : null}
          <ImageWrapper styled={!loaded ? { display: "none" } : {}}>
            <DokterImg
              error={error ? 1 : 0}
              src={`${url}full/${dokter.foto}`}
              onLoad={() => setLoaded(true)}
              // Jika tidak ada Foto
              onError={e => {
                setError(true);
                if (e.target.src !== `${NothingSvg}`) {
                  e.target.src = `${NothingSvg}`;
                }
              }}
            />
          </ImageWrapper>
          <DokterDetails>
            <HeaderWrapper>
              <Title>{dokter.nama_dokter}</Title>
              <SubTitle>{dokter.nama_poli}</SubTitle>
            </HeaderWrapper>
            <Heading>Pendidikan</Heading>
            <Text>
              {dokter.pendidikan ? dokter.pendidikan : "Data sedang di perbaharui!"}
            </Text>
            <Heading>Profile</Heading>
            <Text>
              {dokter.pengantar ? dokter.pengantar : "Data sedang di perbaharui!"}
            </Text>
            <Heading>Jadwal Eksekutif</Heading>
            <DetailsWrapper>
              {jadwal.loading ? <Loader /> : <JadwalList namaKlinik="Eksekutif" jadwals={jadwal} />}
            </DetailsWrapper>

            <Heading>Jadwal Reguler</Heading>
            <DetailsWrapper>
              {jadwal.loading ? <Loader /> : <JadwalList namaKlinik="Reguler" jadwals={jadwal} />}
            </DetailsWrapper>

            <ButtonsWrapper>
              <LeftButtons>
                <Heading>Share</Heading>
                <ShareWrapper>
                  <FacebookShareButton
                    url={`https://dokter.rspersahabatan.co.id/dokter/${dokter.id}`}
                    quote={dokter.nama_dokter}
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={`https://dokter.rspersahabatan.co.id/dokter/${dokter.id}`}
                    quote={dokter.nama_dokter}
                  >
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <TelegramShareButton
                    url={`https://dokter.rspersahabatan.co.id/dokter/${dokter.id}`}
                    quote={dokter.nama_dokter}
                  >
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </ShareWrapper>
              </LeftButtons>
              {renderBack()}
            </ButtonsWrapper>
          </DokterDetails>
        </DokterWrapper>
      </LazyLoad>
      <Header title="Rekomendasi" subtitle="Dokter" />
      {rekomendasi.loading ? <Loader /> : <DokterList dokters={rekomendasi} />}
    </Wrapper>
  );
};

const mapStateToProps = ({ geral, dokter, rekomendasi, jadwal }) => ({
  geral,
  dokter,
  rekomendasi,
  jadwal
});

export default connect(mapStateToProps, {
  getDokter,
  clearDokter,
  getRekomendasi,
  getJadwal,
  clearJadwal,
  clearRekomendasi
})(Dokter);
