import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StickyBox from "react-sticky-box";

import Logo from "../components/Logo";
import MenuItem from "../components/MenuItem";
import btnLogo from "../svg/btn-logo.svg";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  width: 25rem;
  padding: 2rem;
  color: var(--color-primary-dark);
  border-right: 1px solid var(--border-color);
`;

const Heading = styled.h2`
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: -0.5px;
  margin: 0 0 1rem 1rem;
  &:not(:first-child) {
    margin-top: 4rem;
  }
`;

const LinkWrap = styled(Link)`
  text-decoration: none;
  display: block;
  outline: none;
  margin-bottom: 0.5rem;
`;

const StyledCoffe = styled.a`
  display: flex !important;
  outline: none;
  justify-content: center !important;
  align-items: center !important;
  padding: 0.5rem 2rem;
  color: #000000;
  background-color: #ffffff;
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid transparent;
  text-decoration: none;
  font-family: "Montserrat";
  font-size: 1.2rem;
  letter-spacing: 0.6px;
  box-shadow: 0px 1px 2px rgba(190, 190, 190, 0.5);
  margin: 2rem auto;
  transition: 0.3s all linear;
  &img {
    width: 27px;
    box-shadow: none;
    border: none;
    vertical-align: middle;
  }
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    box-shadow: 0px 1px 2px 2px rgba(190, 190, 190, 0.5);
    opacity: 0.85;
    color: #000000;
  }
`;

const CopyRight = styled.div`
  display: flex;
  align-self: center;
  align-items: center;
  color: ${props => (props.mobile ? "#FFF" : "var(--color-primary-dark)")};
  margin-bottom: ${props => (props.mobile ? "2rem" : "")};
`;

const StyledLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  margin-left: 4px;
  color: inherit;
`;

const Svg = styled.img`
  max-width: 100%;
  height: 3rem;
`;

const Sidebar = ({ spesialis, staticCategories, selected }) => {
  return (
    <StickyBox>
      <Wrapper>
        <Logo />
        <Heading>Discover</Heading>
        {renderStatic(staticCategories, selected)}
        <Heading>Spesialis</Heading>
        {renderSpesialis(spesialis, selected)}
        <StyledCoffe target="_blank" rel="noopener noreferrer" href="go">
          <Svg
            src={`${btnLogo}`}
            alt="Data olah Prima SIRS"
            style={{ margin: "2rem 0" }}
          />
          <span style={{ marginLeft: "5px" }}>Data Prima SIRS</span>
        </StyledCoffe>
        <CopyRight>
          Copyright ©
          <StyledLink href="https://rspersahabatan.co.id/">
            RS Persahabatan
          </StyledLink>
        </CopyRight>
      </Wrapper>
    </StickyBox>
  );
};

function renderStatic(categories, selected, setisOpened) {
  return categories.map((category, id) => (
    <LinkWrap
      to={`${process.env.PUBLIC_URL}/discover/${category}`}
      key={id}
      onClick={setisOpened ? () => setisOpened(false) : null}
    >
      <MenuItem
        mobile={setisOpened ? 1 : 0}
        title={category}
        selected={category === selected ? true : false}
      />
    </LinkWrap>
  ));
}

function renderSpesialis(spesialis, selected, setisOpened) {
  return spesialis.map(spesial => (
    <LinkWrap
      to={`${process.env.PUBLIC_URL}/spesialis/${spesial.nama}`}
      key={spesial.id}
      onClick={setisOpened ? () => setisOpened(false) : null}
      data-umami-event={`sidebar--${spesial.nama.replace(/ +/g, '-')}`}
    >
      <MenuItem
        mobile={setisOpened ? 1 : 0}
        title={spesial.nama}
        selected={spesial.nama === selected ? true : false}
      />
    </LinkWrap>
  ));
}

const mapStateToProps = ({ geral }) => {
  return {
    spesialis: geral.spesialis,
    staticCategories: geral.staticCategories,
    selected: geral.selected
  };
};

export default connect(mapStateToProps)(Sidebar);
