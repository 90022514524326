import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? `http://localhost/api/dokter/`
    : process.env.REACT_APP_BE;

let token = process.env.REACT_APP_API;

const headers = { Authorization: `Persahabatan ${token}` };

export default axios.create({
  baseURL: url,
  timeout: 50000,
  headers: headers
});
